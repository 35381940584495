import React from "react";
import Button from "../../../components/common/Button";
import Layout from "../../../components/common/Layout";
import Meta from "../../../components/common/Meta";

const ThankYouPage = () => {
  const meta = {
    title:
      "トライアルお申し込みありがとうございます｜正規販売代理店（株）Digital Stacks",
    description:
      "Freshworksの無料トライアルのご依頼を受け付けました。後ほど担当者よりご連絡いたします。",
    pathname: "/tp/freshworks/free-trial-thanks/",
  };
  return (
    <Layout>
      <Meta meta={meta} />
      <div className="flex justify-center items-center px-4 flex-col mt-16 mb-[80px]">
        <h1 className="text-center text-[40px] max-w-[600px] mx-auto leading-[60px] mb-4 text-dsc-title">
          Freshworksの無料トライアルのご依頼を受け付けました。
        </h1>
        <p className="text-sm leading-4 text-dsc-body text-center mb-10">
          後ほど担当者よりご連絡いたします。
        </p>
        <Button
          title="メインページに戻る"
          size="lg"
          path="/"
          className="px-4 lg:px-4 lg:py-2 py-2 bg-[#3480E3] hover:bg-[#17A1E6]"
        />
      </div>
    </Layout>
  );
};

export default ThankYouPage;
